.main {
    display: flex;
    position: relative;
}

.row {
    position: relative;
    z-index: 2;
    display: flex;
}

.circle {
    font-family: system-ui;
    font-size: 14px;
    font-weight: 600;
    color: #262a3e;
    line-height: 32px;
    background: #fff;
    border: solid 1px #d5d8e3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    width: 32px;
    height: 32px;
    border-radius: 16px;
    position: relative;
    text-align: center;
}

.blue {
    color: #fff;
    background: #1A4FD6;
    border: solid 1px #00a3ff;
}

.red {
    color: #fff;
    background: #e90000;
    border: solid 1px #e90000;
}

.bar {
    position: relative;
    z-index: 1;
    width: 100px;
    height: 2px;
    background: #eee;
    margin: 16px 0;
}

.progress {
    position: absolute;
    z-index: 1;
    width: 0px;
    height: 2px;
    background: #1A4FD6;
    margin: 16px 0;
    animation: fullw 3s forwards;
}

.loading {
    position: absolute;
    z-index: 1;
    width: 0px;
    height: 2px;
    background: linear-gradient(to right, #1A4FD6, #f5f6fa);
    margin: 16px 0;
    animation: barinfinite 1s infinite;
}

@keyframes fullw {
    0% {
        width: 0px;
    }

    100% {
        width: 100px;
    }
}

@keyframes barinfinite {
    0% {
        width: 0px;
    }

    100% {
        width: 132px;
    }
}

@-webkit-keyframes fullw {
    0% {
        width: 0px;
    }

    100% {
        width: 100px;
    }
}

.outer-circle {
    background-image: linear-gradient(#1A4FD6, #456ed6, #f2f2f2);
    width: 32px;
    height: 32px;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    border-width: 0;
    -webkit-animation: rotateCircle 2s linear infinite;
    animation: rotateCircle 2s linear infinite;
    padding: 2px;
    display: table;
}

.outer-circle .inner-circle {
    background: #fff;
    height: 32px;
    width: 32px;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    border-width: 1px;
    display: table;
    animation: rotateCircle 2s linear infinite reverse;
}

.outer-circle .text {
    vertical-align: middle;
    display: table-cell;
    text-align: center;
    color: #1A4FD6;
    font-family: system-ui;
    font-size: 14px;
    font-weight: 600;
}

@-webkit-keyframes rotateCircle {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotateCircle {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.hold-text {
    color: #d2d2d2
}